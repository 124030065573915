import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface OrderStep1Props {
    stepTitle?: string;
}

const OrderStep1: React.FC<OrderStep1Props> = ({stepTitle = '?'}) => {
    const {t} = useTranslation();

    const nextStepPath = "/2";

    return (
        <div>
            <h2 className="text-4xl">{stepTitle}{/* / {t('language', {test: stepTitle})}*/}</h2>
            <div className="my-10">
                <form>
                    <fieldset className="grid gap-5">
                        <div>
                            <label htmlFor="naam">
                                Naam&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                            </label>
                            <input type="text" id="naam" required name="fields[naam]" placeholder="Volledige naam"/>
                        </div>
                        <div className="grid gap-5 grid-cols-2">
                            <div>
                                <label htmlFor="eMailadres">
                                    E-mailadres&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                                </label>
                                <input type="email" id="eMailadres" required name="fields[eMailadres]" value="" placeholder="E-mailadres" autoComplete="email"/>
                            </div>
                            <div>
                                <label htmlFor="telefoon">Telefoon</label>
                                <input type="tel" id="telefoon" name="fields[telefoon]" placeholder="Telefoon" autoComplete="tel"/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="vraag">
                                Vraag&nbsp;<span className="text-danger-500" aria-hidden="true">*</span>
                            </label>
                            <textarea id="vraag" name="fields[vraag]" rows="3" placeholder="Uw vraag of opmerking." required="" x-data="" x-autosize=""></textarea>
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" id="privacy" name="fields[privacy]" value="1" required/>
                            <label htmlFor="privacy">
                                Ik ga akkoord met de <a target="_blank" rel="noopener noreferrer nofollow" href="https://elietpaas.ddev.site/nl/privacybeleid">privacyvoorwaarden</a>.
                            </label>
                        </div>
                        <div className="mt-5">
                            <button type="submit" className="btn btn-sm" data-submit-action="submit">Verzenden</button>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div className="btnContainer">
                <Link to={nextStepPath} className="btn btn-outline" title="Proceed to step 2">
                    To step 2
                </Link>
            </div>
        </div>
    )
}

export default OrderStep1